<template>
  <div>
    <consult></consult>
    <navbar :title="detail.title"></navbar>
    <div class="box">
      <div class="title">
        {{ detail.title }}
      </div>
      <div class="footer">
        <div><img src="../assets/time.png" alt="" />{{ detail.time }}</div>
        <div class="right">
          <div>
            <img src="../assets/yanjing.png" alt="" />{{ detail.views }}
          </div>
          <div>
            <img src="../assets/zhuanfa_icon@2x.png" alt="" />{{
              detail.forward
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div v-html="detail.content"></div>
    </div>
    <div style="height: 1.12rem"></div>
    <div class="function">
      <div class="l" v-if="detail.collection == 0" @click="collect">
        <img src="../assets/ai2.png" /> 收藏
      </div>
      <div class="l" v-if="detail.collection == 1" @click="cancel_collection">
        <img src="../assets/ai1.png" /> 取消收藏
      </div>
      <div class="fx" @click="share"><img src="../assets/fx1.png" /> 转发</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      detail: {},
      flag: true,
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions([
      "getnewsDet",
      "getcollection",
      "getcancel_collection",
      "getSignPackage",
      "getforward",
      "getnewsForward",
    ]),
    collect() {
      if (this.flag == false) {
        this.$dialog
          .confirm({
            title: "",
            message: "登录后可收藏",
          })
          .then(() => {
            this.$router.push("/user");
          })
          .catch(() => {});
        return;
      }
      this.getcollection({
        news_id: this.$route.query.id, //新闻id
        member_id: this.userinfo.id, //会员id
        type: 1, //1. 新闻 2. 课程 3. 补贴地址
      }).then((res) => {
        if (res.SuccessCode == 200) {
          this.detail.collection = 1;
          this.$toast.success("收藏成功");
        } else {
          this.$toast.fail(res.Message);
        }
      });
    },
    cancel_collection() {
      if (this.flag == false) {
        this.$dialog
          .confirm({
            title: "",
            message: "登录后可收藏",
          })
          .then(() => {
            this.$router.push("/user");
          })
          .catch(() => {});
        return;
      }
      this.getcancel_collection({
        news_id: this.$route.query.id,
        member_id: this.userinfo.id,
        type: 1,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          this.detail.collection = 0;
          this.$toast.success("取消成功");
        } else {
          this.$toast.fail(res.Message);
        }
      });
    },
    share() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        this.$toast("点击右上角...进行分享");
      } else {
        //非微信浏览器
        this.$toast("请在手机微信端运行");
      }
    },
  },
  mounted() {
    var urls = window.location.href;
    if (this.userinfo) {
      var url = urls + `&invitation=${this.userinfo.invitation}`;
    } else {
      var url = urls + `&invitation=${this.invitationinif.invitation}`;
    }
    let that = this;
    if (this.userinfo) {
      this.getnewsDet({
        id: this.$route.query.id,
        member_id: this.userinfo.id,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          let img = "";
          if (res.ResponseBody.style == 1) {
            img = res.ResponseBody.picture_two;
          }
          if (res.ResponseBody.style == 2) {
            img = res.ResponseBody.picture_one;
          }
          if (res.ResponseBody.style == 3) {
            img =
              "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg";
          }

          this.detail = res.ResponseBody;
          // that.$store.commit("SET_shareinformation", {
          //   title: that.detail.profiles,
          //   imgurl: img,
          //   type: 1,
          // });

          this.getSignPackage({
            url: urls,
          }).then((res) => {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
              timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
              signature: res.ResponseBody.signature, // 必填，签名
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });
            wx.ready(function (res) {
              wx.onMenuShareTimeline({
                title: that.detail.profiles, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl: img, // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  that.getnewsForward({
                    id: that.detail.id,
                  });
                },
              });

              wx.onMenuShareAppMessage({
                title: that.detail.profiles, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl: img, // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  if (that.path == "/policydetails") {
                    that.getnewsForward({
                      id: that.detail.id,
                    });
                  }
                },
              });
            });
            wx.error(function (eer) {
              console.log(eer, 3333);
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
          });
        } else {
        }
      });
    } else {
      // this.$dialog
      //   .confirm({
      //     title: "提示",
      //     message: "为了更好的体验请登录",
      //   })
      //   .then(() => {
      //     this.$router.push("/user");
      //   })
      //   .catch(() => {});

      this.getnewsDet({
        id: this.$route.query.id,
        member_id: 262,
      }).then((res) => {
        this.flag = false;
        if (res.SuccessCode == 200) {
          let img = "";
          if (res.ResponseBody.style == 1) {
            img = res.ResponseBody.picture_two;
          }
          if (res.ResponseBody.style == 2) {
            img = res.ResponseBody.picture_one;
          }
          if (res.ResponseBody.style == 3) {
            img =
              "https://xuexh.zpt.udao.sh.cn/file/62fb49698aa7fe726dd708d913ba27b7.jpg";
          }
          this.detail = res.ResponseBody;
          // that.$store.commit("SET_shareinformation", {
          //   title: that.detail.profiles,
          //   imgurl: img,
          //   type: 1,
          // });

          this.getSignPackage({
            url: urls,
          }).then((res) => {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.ResponseBody.appId, // 必填，公众号的唯一标识
              timestamp: res.ResponseBody.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.ResponseBody.nonceStr, // 必填，生成签名的随机串
              signature: res.ResponseBody.signature, // 必填，签名
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 必填，需要使用的JS接口列表
            });
            wx.ready(function (res) {
              console.log(123123123);
              wx.onMenuShareTimeline({
                title: that.detail.profiles, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl: img, // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  that.getnewsForward({
                    id: that.detail.id,
                  });
                },
              });

              wx.onMenuShareAppMessage({
                title: that.detail.profiles, // 分享标题
                desc: "沪惠学", // 分享描述
                link: url, //绑定到微信公众号上的那个跳转地址，不晓得可以百度是什么！
                imgUrl: img, // 分享图标
                success: function () {
                  //记录分享次数
                  if (that.userinfo) {
                    that.getforward({
                      member_id: that.userinfo.id,
                      invitation: that.userinfo.invitation,
                      type: "2",
                    });
                  }

                  that.getnewsForward({
                    id: that.detail.id,
                  });
                },
              });
            });
            wx.error(function (eer) {
              console.log(eer, 3333);
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            });
          });
        } else {
        }
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0.25rem;
  box-sizing: border-box;
  .title {
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
  }
  .footer {
    margin-top: 0.08rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #cdcdcd;
    .right {
      display: flex;
      div {
        margin-left: 0.33rem;
      }
    }
    img {
      margin-right: 0.1rem;
      width: 0.22rem;
      height: auto;
    }
  }
}
.content {
  width: 7.5rem;
  font-size: 0.28rem;
  img {
    width: 100%;
  }
  // overflow: hidden;
}
.function {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 0.01rem solid #7f7f7f;
  height: 1.12rem;
  img {
    width: 0.6rem;
    vertical-align: middle;
  }
  .l {
    border-right: 0.01rem solid #7f7f7f;
  }
  div {
    flex: 1;
    text-align: center;
  }
  line-height: 1.12rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
</style>